/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Svg } from '../../../Svg';
import { useSideIcon } from './sideIcon.hook';
import { ISideIcon } from './sideIcon.models';
import * as S from './sideIcon.style';

export const SideIcon: React.FC<ISideIcon> = ({
  btnProps,
  disabled,
  error,
  isClearable,
  loading,
  onSvgAnimationEnd,
  svg,
  svgClassName,
  variant,
}) => {
  const { iconColor, iconErrorA, iconLoadingA } = useSideIcon({
    disabled,
    error,
    loading,
  });

  switch (true) {
    case iconLoadingA.shouldRender:
      return (
        <S.IconLoading className={iconLoadingA.animationClass} variant={variant}>
          <Svg
            color="#e60000"
            name="spinner"
            onAnimationEnd={iconLoadingA.onAnimationEnd}
            width={24}
          />
        </S.IconLoading>
      );
    case svg && !btnProps:
      return (
        <S.Icon className={svgClassName} variant={variant}>
          <Svg color={iconColor} name={svg!} onAnimationEnd={onSvgAnimationEnd} width={24} />
        </S.Icon>
      );

    case svg && !!btnProps:
      return (
        <S.IconButton
          type="button"
          {...btnProps}
          className={svgClassName}
          disabled={disabled || btnProps!.disabled}
          isClearable={isClearable}
          variant={variant}
        >
          <Svg color={iconColor} name={svg!} onAnimationEnd={onSvgAnimationEnd} width={24} />
        </S.IconButton>
      );

    case !svg && iconErrorA.shouldRender && !variant.startsWith('mva'):
      return (
        <S.IconError className={iconErrorA.animationClass} variant={variant}>
          <Svg color="#bd0000" name="error" onAnimationEnd={iconErrorA.onAnimationEnd} width={24} />
        </S.IconError>
      );

    default:
      return null;
  }
};

