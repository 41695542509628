import styled, { css } from 'styled-components';
import { animations, pxToRem } from '@vfit/shared/themes';
import { IInputsForSC } from '../../inputs.models';

const noAnimation = css`
  & > svg {
    animation: none !important;
  }
`;

const fadeAnimation = css`
  &.icon-error-enter > svg {
    animation: ${animations.fadeIn} 0.3s forwards;
  }

  &.icon-error-exit > svg {
    animation: ${animations.fadeOut} 0.3s forwards;
  }
`;

const spinAnimation = css`
  &.icon-loading-enter > svg {
    animation: ${animations.spin} 2s linear infinite;
  }
`;

const dashAnimation = css`
  &.icon-loading-enter > svg > circle {
    animation: ${animations.dash} 1.5s ease-in-out infinite;
  }
`;

const variants = {
  svgContainer: {
    animated: css`
      border-radius: ${pxToRem(0, 5, 5, 0)};
      padding: ${pxToRem(18, 16)};
    `,
    'mva:animated': css`
      border-radius: ${pxToRem(0, 6, 6, 0)};
      padding: ${pxToRem(10, 16)};
    `,
    'mva:static': css`
      border-radius: ${pxToRem(0, 6, 6, 0)};
      padding: ${pxToRem(10, 16)};
    `,
  },
};

const svgContainer = css<IInputsForSC>`
  position: absolute;
  right: 0;
  top: 0;

  & path {
    transition: all 0.3s ease;
  }

  ${({ variant }) => (variant ? variants.svgContainer[variant] : '')}
`;

export const Icon = styled.span<IInputsForSC>`
  ${svgContainer}
  pointer-events: none;
`;

export const IconLoading = styled.span<IInputsForSC>`
  ${svgContainer}
  ${spinAnimation}
  ${dashAnimation}
  pointer-events: none;
`;

export const IconButton = styled.button<IInputsForSC & { isClearable?: boolean }>`
  ${svgContainer}
  ${({ isClearable }) => (!isClearable ? '' : noAnimation)};
  border: none;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
`;

export const IconError = styled.span<IInputsForSC>`
  ${svgContainer}
  ${fadeAnimation}
  pointer-events: none;
`;

