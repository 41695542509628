/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { forwardRef } from 'react';
import { ErrorMessage } from '../ErrorMessage';
import { useInputs } from '../../inputs.hook';
import * as I from '../../inputs.style';
import { SideIcon } from '../SideIcon';
import { IInput } from './input.models';
import * as S from './input.style';
import { useInput } from './input.hook';

export const Input = forwardRef<HTMLInputElement, IInput>(
  (
    {
      bg = '#fff',
      btnProps: _btnProps,
      className,
      clearable,
      disabled: _disabled,
      error: _error,
      hidden,
      label,
      loading,
      notDisableIfLoading,
      onAnimationEnd,
      onBlur,
      onChange,
      onClear: _onClear,
      onClearCallback,
      onSvgAnimationEnd,
      placeholder,
      svg: _svg,
      svgClassName,
      style,
      styled,
      type = 'text',
      variant = 'animated',
      ...rest
    },
    ref
  ) => {
    const { disabled, id, error } = useInputs({
      _disabled,
      _error,
      loading,
      notDisableIfLoading,
    });

    const { btnProps, handleBlur, handleChange, isClearable, refCallback, svg } = useInput({
      _btnProps,
      _onClear,
      _svg,
      clearable,
      disabled,
      onBlur,
      onChange,
      onClearCallback,
      ref,
    });

    return (
      <I.Container
        className={className}
        hidden={hidden}
        onAnimationEnd={onAnimationEnd}
        style={style}
        styled={styled}
        variant={variant}
      >
        <I.Label>
          <S.Input
            aria-errormessage={id}
            aria-invalid={!!error}
            bg={bg}
            disabled={disabled}
            error={error}
            hidden={hidden}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={variant?.includes('static') ? placeholder : placeholder || label}
            ref={refCallback}
            svg={svg}
            type={type}
            variant={variant}
            {...rest}
          />
          <I.LabelPlaceholder bg={bg} disabled={disabled} variant={variant}>
            {label}
          </I.LabelPlaceholder>
          <SideIcon
            btnProps={btnProps}
            error={error}
            disabled={disabled}
            isClearable={isClearable}
            loading={loading}
            onSvgAnimationEnd={onSvgAnimationEnd}
            svg={svg}
            svgClassName={svgClassName}
            variant={variant}
          />
        </I.Label>
        <ErrorMessage error={error} id={id} variant={variant} />
      </I.Container>
    );
  }
);

