import { Svg } from '../../../Svg';
import { useErrorMessage } from './errorMessage.hook';
import { IErrorMessage } from './errorMessage.models';
import * as S from './errorMessage.style';

export const ErrorMessage: React.FC<IErrorMessage> = ({ error, id, variant = 'animated' }) => {
  const { errorA } = useErrorMessage({ error });

  return errorA.shouldRender ? (
    <S.ErrorMessage
      className={errorA.animationClass}
      id={id}
      onAnimationEnd={errorA.onAnimationEnd}
      role="alert"
      variant={variant}
    >
      {variant.startsWith('mva') && <Svg color="#bd0000" name="error" width={16} />}
      <span>{errorA.delayedThings}</span>
    </S.ErrorMessage>
  ) : null;
};

