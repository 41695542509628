import styled, { css } from 'styled-components';
import { animations, colors, getTypography, pxToRem } from '@vfit/shared/themes';
import { IInputsForSC } from '../../inputs.models';

const fadeAnimation = css`
  &.error-enter {
    animation: ${animations.fadeIn} 0.3s forwards;
  }

  &.error-exit {
    animation: ${animations.fadeOut} 0.3s forwards;
  }
`;

const variants = {
  errorMessage: {
    animated: css`
      ${getTypography('footnote2.regular')}
      height: ${pxToRem(24)};
    `,
    'mva:animated': css`
      ${getTypography('footnote1.regular')}
      height: ${pxToRem(18)};
    `,
    'mva:static': css`
      ${getTypography('footnote1.regular')}
      height: ${pxToRem(18)};
    `,
  },
};

export const ErrorMessage = styled.p<IInputsForSC>`
  ${fadeAnimation}
  align-items: center;
  bottom: 0;
  color: ${colors.$bd0000};
  column-gap: ${pxToRem(4)};
  display: flex;
  left: 0;
  margin: 0;
  position: absolute;
  ${({ variant }) => (variant ? variants.errorMessage[variant] : '')}
`;

