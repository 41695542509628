import { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import { stringToAsciiCode } from '@vfit/shared/themes';
import {
  createSyntheticEvent,
  useAnimation,
  useDeviceType,
  useOnClickOutside,
} from '@vfit/shared/hooks';
import { IUseSelect } from './select.models';

export const useSelect = ({
  _btnProps,
  _onSvgAnimationEnd,
  _svg,
  _svgClassName,
  children,
  defaultValue,
  disableFiltering,
  items,
  label,
  onBlur,
  onChange,
  onClick,
  ref,
  unordered,
  value,
  variant,
  hideFilteredItems,
  isAddressList,
}: IUseSelect) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const innerRef = useRef<HTMLInputElement | null>(null);
  const [errorNoSelection, setErrorNoSelection] = useState<string | null>(null);

  const [filteredItems, setFilteredItems] = useState(
    unordered
      ? items
      : // Sort method changes the original array
        [...items].sort((first, second) =>
          first.localeCompare(second, undefined, { numeric: true })
        )
  );

  const [open, setOpen] = useState(false);
  const [output, setOutput] = useState(defaultValue || value);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  useOnClickOutside(containerRef.current, close);

  const { isMobile } = useDeviceType();
  const id = useId();

  const itemSize = useMemo(() => (variant && !variant.startsWith('mva') ? 40 : 50), [variant]);

  const svg = _svg || 'chevronDown';

  const arrowA = useAnimation({ prefix: 'arrow', show: open, skipOnMount: true });

  const optionsThingsToWait = useMemo(
    () => ({ filteredItems, children }),
    [children, filteredItems]
  );

  const optionsA = useAnimation<{
    filteredItems: string[];
    children: React.ReactNode;
  }>({
    show: open && (filteredItems.length > 0 || !!children),
    thingsToWait: optionsThingsToWait,
  });

  const svgClassName = useMemo(
    () => (svg === 'chevronDown' ? arrowA.animationClass : _svgClassName),
    [_svgClassName, arrowA.animationClass, svg]
  );

  const onSvgAnimationEnd = useCallback(
    () => _onSvgAnimationEnd || arrowA.onAnimationEnd,
    [_onSvgAnimationEnd, arrowA.onAnimationEnd]
  );

  const refCallback = useCallback(
    (node: HTMLInputElement) => {
      if (node) {
        innerRef.current = node;

        if (ref) {
          if (typeof ref === 'function') {
            ref(node);
          } else {
            // eslint-disable-next-line no-param-reassign
            ref.current = node;
          }
        }
      }
    },
    [ref]
  );

  const getFilteredItems = useCallback(
    (text?: string) => {
      if (!text) {
        setErrorNoSelection(null);
        return items;
      }

      const t = text.toLowerCase();

      const localFilteredItems = items
        ?.filter((s) => s.toLowerCase().indexOf(t) !== -1)
        .sort((first, second) => {
          const f = first.toLowerCase();
          const s = second.toLowerCase();

          if (f.startsWith(t) && !s.startsWith(t)) {
            return -1;
          }
          if (!f.startsWith(t) && s.startsWith(t)) {
            return 1;
          }
          if (f < s) {
            return -1;
          }
          if (f > s) {
            return 1;
          }
          return 0;
        });

      // No filtered Element set erro
      if (localFilteredItems.length === 0 && isAddressList) {
        const localFilteredItemsForAddress = items.sort((first, second) => {
          const f = first.toLowerCase();
          const s = second.toLowerCase();

          if (f.startsWith(t) && !s.startsWith(t)) {
            return -1;
          }
          if (!f.startsWith(t) && s.startsWith(t)) {
            return 1;
          }
          if (f < s) {
            return -1;
          }
          if (f > s) {
            return 1;
          }
          return 0;
        });

        if (localFilteredItemsForAddress && localFilteredItemsForAddress.length > 0) {
          return localFilteredItemsForAddress;
        }
        setErrorNoSelection(`Nessun risultato per "${text}"`);
      }
      if (localFilteredItems.length === 0) {
        setErrorNoSelection(`Nessun risultato per "${text}"`);
      }
       else {
        setErrorNoSelection(null);
      }

      return localFilteredItems;
    },
    [items]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilteredItems(getFilteredItems(e.target.value));
      setOutput(e.target.value);

      onChange?.(e);
    },
    [getFilteredItems, onChange]
  );

  const onSelect = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      if (innerRef.current) {
        // eslint-disable-next-line no-param-reassign
        innerRef.current.value = e.currentTarget.getAttribute('value') || '';

        const syntheticEvent = createSyntheticEvent(innerRef.current, 'change');

        if (syntheticEvent) {
          handleChange?.(syntheticEvent);
        }

        // This is a trick to force an update in the input
        setTimeout(() => {
          innerRef?.current?.focus();
          innerRef?.current?.blur();
          close();
        }, 1);
      }
    },
    [close, handleChange]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      onBlur?.(e);
    },
    [onBlur]
  );

  const toggleOpen = useCallback(() => {
    setOpen((o) => !o);
  }, []);

  const handleClickInput = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      if (!open) {
        setOpen(true);
      }

      onClick?.(e);
    },
    [onClick, open]
  );

  const handleClickInputAndButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      toggleOpen();

      onClick?.(e);
    },
    [onClick, toggleOpen]
  );

  const getItemKey = useCallback(
    (index: number, data: Array<string>) => stringToAsciiCode(data[index]),
    []
  );

  const btnProps = useMemo(
    () =>
      !_btnProps?.onClick
        ? {
            ..._btnProps,
            'aria-controls': id,
            'aria-expanded': open,
            'aria-label': label,
            onClick: toggleOpen,
            tabIndex: -1,
          }
        : {
            'aria-controls': id,
            'aria-label': label,
            ..._btnProps,
          },
    [_btnProps, id, open, toggleOpen, label]
  );

  useEffect(() => {
    if (innerRef.current && items) {
      setFilteredItems(getFilteredItems(innerRef.current.value));

      setOutput(innerRef.current.value);
    }
  }, [getFilteredItems, items]);

  return {
    arrowA,
    btnProps,
    close,
    containerRef,
    getItemKey,
    handleBlur,
    handleChange,
    handleClickInput,
    handleClickInputAndButton,
    id,
    innerRef,
    isMobile,
    itemSize,
    onSelect,
    onSvgAnimationEnd,
    open,
    optionsA,
    output,
    refCallback,
    svg,
    svgClassName,
    errorNoSelection,
  };
};
