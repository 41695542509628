import { useId } from 'react';
import { IUseInputs } from './inputs.models';

export const useInputs = ({ _disabled, _error, loading, notDisableIfLoading }: IUseInputs) => {
  const id = useId();

  const disabled = (!notDisableIfLoading && loading) || !!_disabled;

  const error = _error && !disabled ? _error : '';

  return {
    disabled,
    error,
    id,
  };
};

