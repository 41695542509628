import { forwardRef } from 'react';
import { Input } from '../Input';
import { FlatList, InputAndButton, List } from './components';
import { ISelect } from './select.models';
import { useSelect } from './select.hook';
import * as S from './select.style';

export const Select = forwardRef<HTMLInputElement, ISelect>(
  (
    {
      btnProps: _btnProps,
      defaultValue,
      children,
      disableFiltering,
      editable,
      error,
      items,
      label,
      loading,
      onBlur,
      onChange,
      onClearCallback,
      onClick,
      onSvgAnimationEnd: _onSvgAnimationEnd,
      style,
      styled,
      svg: _svg,
      svgClassName: _svgClassName,
      unordered,
      value,
      variant = 'animated',
      hideFilteredItems = false,
      isAddressList: _isAddressList,
      ...rest
    },
    ref
  ) => {
    const {
      btnProps,
      close,
      containerRef,
      getItemKey,
      handleBlur,
      handleChange,
      handleClickInput,
      handleClickInputAndButton,
      id,
      innerRef,
      isMobile,
      itemSize,
      onSelect,
      onSvgAnimationEnd,
      open,
      optionsA,
      output,
      refCallback,
      svg,
      svgClassName,
      errorNoSelection,
    } = useSelect({
      _btnProps,
      _onSvgAnimationEnd,
      _svg,
      _svgClassName,
      children,
      defaultValue,
      disableFiltering,
      items,
      onBlur,
      onChange,
      onClick,
      ref,
      unordered,
      value,
      variant,
      hideFilteredItems,
      isAddressList: _isAddressList,
    });

    return (
      <S.Container styled={styled} ref={containerRef} style={style}>
        {editable ? (
          <Input
            {...rest}
            aria-autocomplete="none"
            aria-controls={id}
            aria-expanded={open}
            defaultValue={defaultValue}
            btnProps={btnProps}
            error={errorNoSelection || error}
            label={label}
            loading={loading}
            onBlur={handleBlur}
            onChange={handleChange}
            onClearCallback={onClearCallback}
            onClick={handleClickInput}
            onSvgAnimationEnd={onSvgAnimationEnd}
            ref={refCallback}
            role="combobox"
            svg={svg}
            svgClassName={svgClassName}
            value={value}
            variant={variant}
          />
        ) : (
          <InputAndButton
            {...rest}
            aria-autocomplete="none"
            aria-controls={id}
            aria-expanded={open}
            aria-label={label}
            defaultValue={defaultValue}
            onClick={handleClickInputAndButton}
            error={errorNoSelection || error}
            label={label}
            loading={loading}
            onBlur={handleBlur}
            onChange={handleChange}
            onSvgAnimationEnd={onSvgAnimationEnd}
            ref={refCallback}
            role="combobox"
            svg={svg}
            svgClassName={svgClassName}
            value={value}
            variant={variant}
          >
            {output}
          </InputAndButton>
        )}

        {optionsA.shouldRender && (
          <S.Options
            aria-label={label}
            className={optionsA.animationClass}
            error={errorNoSelection || error}
            id={id}
            onAnimationEnd={optionsA.onAnimationEnd}
            role="listbox"
            variant={variant}
          >
            {editable ? (
              <FlatList
                filteredItems={optionsA.delayedThings.filteredItems}
                getItemKey={getItemKey}
                innerRef={innerRef}
                isMobile={isMobile}
                itemSize={itemSize}
                onSelect={onSelect}
                variant={variant}
              />
            ) : (
              <List
                filteredItems={optionsA.delayedThings.filteredItems}
                isMobile={isMobile}
                innerRef={innerRef}
                onSelect={onSelect}
                variant={variant}
              />
            )}

            {optionsA.delayedThings.children && (
              <S.Child
                filteredItems={optionsA.delayedThings.filteredItems}
                variant={variant}
                onClick={close}
              >
                {optionsA.delayedThings.children}
              </S.Child>
            )}
          </S.Options>
        )}
      </S.Container>
    );
  }
);

