import { stringToAsciiCode } from '@vfit/shared/themes';
import * as S from '../../select.style';
import { IList } from './list.models';

export const List = ({ filteredItems, isMobile, innerRef, onSelect, variant }: IList) => (
  <>
    {filteredItems.map((item) => (
      <S.Li
        id={stringToAsciiCode(item)}
        key={stringToAsciiCode(item)}
        onClick={(e) => {
          if (!isMobile) {
            e.preventDefault();
          } else {
            onSelect(e);
          }
        }}
        onPointerDown={(e) => {
          if (isMobile) {
            e.preventDefault();
          } else {
            onSelect(e);
          }
        }}
        role="option"
        aria-selected={innerRef.current?.value === item}
        value={item}
        variant={variant}
      >
        {item}
      </S.Li>
    ))}
  </>
);

