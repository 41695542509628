import styled, { css } from 'styled-components';
import { colors, getTypography, pxToRem } from '@vfit/shared/themes';
import { IInputsForSC } from '../../../../inputs.models';

const variants = {
  button: {
    animated: css<IInputsForSC>`
      border-color: ${({ error }) => (!error ? colors.$bebebe : colors.$bd0000)};
      border-radius: ${pxToRem(5)};
      height: ${pxToRem(60)};
      padding: ${pxToRem(18, 56, 18, 20)};

      &.valued + span {
        ${getTypography('body3.regular')}
        color: ${({ disabled }) => (!disabled ? colors.$262626 : colors.$7e7e7e)};
        transform: translate3d(0, ${pxToRem(-27)}, 0);
      }
    `,
    'mva:animated': css<IInputsForSC>`
      border-color: ${({ error }) => (!error ? colors.$bebebe : colors.$bd0000)};
      border-radius: ${pxToRem(6)};
      ${({ error }) => (error ? `box-shadow: ${pxToRem(0, 0, 0, 1)} ${colors.$bd0000};` : '')}
      height: ${pxToRem(44)};
      padding: ${pxToRem(9, 54, 9, 15)};

      &:focus {
        border-color: ${colors.$0096AD};
        box-shadow: ${pxToRem(0, 0, 0, 1)} ${colors.$0096AD};
      }

      &.valued + span {
        ${getTypography('body3.regular')}
        color: ${({ disabled }) => (!disabled ? colors.$262626 : colors.$7e7e7e)};
        transform: translate3d(0, ${pxToRem(-19)}, 0);
      }
    `,
    'mva:static': css<IInputsForSC>`
      border-color: ${({ error }) => (!error ? colors.$bebebe : colors.$bd0000)};
      border-radius: ${pxToRem(6)};
      ${({ error }) => (error ? `box-shadow: ${pxToRem(0, 0, 0, 1)} ${colors.$bd0000};` : '')}
      height: ${pxToRem(44)};
      padding: ${pxToRem(9, 54, 9, 15)};

      &:focus {
        border-color: ${colors.$0096AD};
        box-shadow: ${pxToRem(0, 0, 0, 1)} ${colors.$0096AD};
      }
    `,
  },
};

export const Button = styled.button<IInputsForSC>`
  ${getTypography('body1.regular')}
  border-width: 1px;
  border-style: solid;
  color: ${({ disabled }) => (!disabled ? colors.$262626 : colors.$7e7e7e)};
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  line-height: 1;
  outline: 0; // TODO This is a bad practice
  text-align: left;
  transition: border-color 0.3s ease;
  width: 100%;

  &::file-selector-button {
    display: none;
  }

  ${({ bg }) => (bg ? `background-color: ${bg};` : '')};
  ${({ variant }) => (variant ? variants.button[variant] : '')}
`;

export const Placeholder = styled.span`
  color: ${colors.$7e7e7e};
  pointer-events: none;
  transition: all 0.3s ease;
`;

