import { useAnimation } from '@vfit/shared/hooks';
import { colors } from '@vfit/shared/themes';
import { useMemo } from 'react';
import { IUseSideButton } from './sideIcon.models';

export const useSideIcon = ({ disabled, error, loading }: IUseSideButton) => {
  const iconLoadingA = useAnimation({ isInfinite: true, prefix: 'icon-loading', show: !!loading });

  const iconErrorA = useAnimation({ prefix: 'icon-error', show: !!error });

  const iconColor = useMemo(() => {
    switch (true) {
      case !!error:
        return colors.$bd0000;
      case disabled:
        return colors.$7e7e7e;
      default:
        return colors.$0d0d0d;
    }
  }, [disabled, error]);

  return { disabled, iconColor, iconErrorA, iconLoadingA };
};

