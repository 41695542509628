import { useCallback, useMemo, useRef, useState } from 'react';
import { createSyntheticEvent } from '@vfit/shared/hooks';
import { IUseInput } from './input.models';

export const useInput = ({
  _btnProps,
  _onClear,
  _svg,
  clearable,
  disabled,
  onBlur,
  onChange,
  onClearCallback,
  ref,
}: IUseInput) => {
  const [isClearable, setIsClearable] = useState(false);

  const innerRef = useRef<HTMLInputElement | null>(null);

  const svg = isClearable ? 'close' : _svg;

  const refCallback = useCallback(
    (node: HTMLInputElement) => {
      if (node) {
        innerRef.current = node;

        if (ref) {
          if (typeof ref === 'function') {
            ref(node);
          } else {
            // eslint-disable-next-line no-param-reassign
            ref.current = node;
          }
        }
      }
    },
    [ref]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (clearable) {
        if (e.target.value && !disabled) {
          setIsClearable(true);
        } else {
          setIsClearable(false);
        }
      }

      onChange?.(e);
    },
    [clearable, disabled, onChange]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (clearable) {
        if (e.target.value) {
          setIsClearable(true);
        } else {
          setIsClearable(false);
        }
      }

      onBlur?.(e);
    },
    [clearable, onBlur]
  );

  const onClear = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      _onClear?.();

      e.preventDefault();

      if (clearable && innerRef.current) {
        // eslint-disable-next-line no-param-reassign
        innerRef.current.value = '';

        const syntheticEvent = createSyntheticEvent(innerRef.current, 'change');

        if (syntheticEvent) {
          handleChange?.(syntheticEvent);
        }

        onClearCallback?.();

        // This is a trick to force an update in the input
        setTimeout(() => {
          innerRef?.current?.focus();
          innerRef?.current?.blur();
        }, 1);
      }
    },
    [_onClear, clearable, handleChange, onClearCallback]
  );

  const btnProps = useMemo(() => {
    if (!clearable || svg !== 'close') {
      return _btnProps;
    }

    return { ..._btnProps, onClick: onClear };
  }, [_btnProps, clearable, onClear, svg]);

  return {
    btnProps,
    handleBlur,
    handleChange,
    isClearable,
    refCallback,
    svg,
  };
};

