import { colors } from './colors';
import { pxToRem } from './utils';

export const shadows = {
  card_overlay: `${pxToRem(0, 2, 8)} ${colors.$000000_16}`,
} as const;

type ShadowsKeys = keyof typeof shadows;
export type Shadows = (typeof shadows)[ShadowsKeys];

