import { useCallback } from 'react';
import { FixedSizeList } from 'react-window';
import { mathRound, stringToAsciiCode } from '@vfit/shared/themes';
import * as S from '../../select.style';
import { IFlatList, IGetItem } from './flatList.models';

export const FlatList = ({
  filteredItems,
  getItemKey,
  innerRef,
  isMobile,
  itemSize,
  onSelect,
  variant,
}: IFlatList) => {
  const getItem = useCallback(
    ({ data, index, style }: IGetItem) => (
      <S.Li
        id={stringToAsciiCode(data[index])}
        onClick={(e) => {
          if (!isMobile) {
            e.preventDefault();
          } else {
            onSelect(e);
          }
        }}
        onPointerDown={(e) => {
          if (isMobile) {
            e.preventDefault();
          } else {
            onSelect(e);
          }
        }}
        role="option"
        aria-selected={innerRef.current?.value === data[index]}
        style={style}
        value={data[index]}
        variant={variant}
      >
        {data[index]}
      </S.Li>
    ),
    [innerRef, isMobile, onSelect, variant]
  );

  return (
    <FixedSizeList
      height={
        filteredItems.length > 3
          ? mathRound(itemSize * 4)
          : mathRound(itemSize * filteredItems.length)
      }
      itemCount={filteredItems.length}
      itemSize={itemSize}
      width="100%"
      itemData={filteredItems}
      itemKey={getItemKey}
    >
      {getItem}
    </FixedSizeList>
  );
};

