import { useAnimation } from '@vfit/shared/hooks';
import { IUseErrorMessage } from './errorMessage.models';

export const useErrorMessage = ({ error }: IUseErrorMessage) => {
  const errorA = useAnimation({
    prefix: 'error',
    show: typeof error === 'string' && !!error,
    thingsToWait: error,
  });

  return { errorA };
};

