import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface UseAnimationProps<T = React.ReactNode> {
  isInfinite?: boolean;
  prefix?: string;
  show: boolean;
  skipOnMount?: boolean;
  thingsToWait?: T;
}

interface UseAnimationObject<T = React.ReactNode> {
  animationClass: string;
  delayedThings: T;
  onAnimationEnd: () => void;
  shouldRender: boolean;
}

/**
 * Custom React hook for managing animations.
 *
 * @param {UseAnimationProps} props - The properties for the hook.
 * @param {unknown} props.thingsToWait - Things to be rendered with a delay.
 * @param {boolean} [props.isInfinite=false] - Whether the animation should be infinite.
 * @param {string} [props.prefix] - The prefix for the animation class.
 * @param {boolean} props.show - Whether the animation should be shown.
 * @param {boolean} [props.skipOnMount=false] - Whether the animation should be skipped on mount.
 * @return {UseAnimationObject} - The animation state and callbacks.
 * @property {string|undefined} animationClass - The animation class based on the show property.
 * @property {unknown} delayedThings - Things to be rendered with a delay.
 * @property {() => void} onAnimationEnd - The callback function to be called when the animation ends.
 * @property {boolean} shouldRender - Whether the children should be rendered.
 */
export const useAnimation = <T = React.ReactNode>({
  isInfinite = false,
  prefix,
  show,
  skipOnMount = false,
  thingsToWait,
}: UseAnimationProps<T>): UseAnimationObject<T> => {
  const [shouldRender, setShouldRender] = useState(show);
  const [delayedThings, setDelayedThings] = useState(thingsToWait);

  const isMounted = useRef(false);

  const enter = prefix ? `${prefix}-enter` : 'enter';
  const exit = prefix ? `${prefix}-exit` : 'exit';

  const animationClass = useMemo(() => {
    if (!isMounted.current && skipOnMount) {
      isMounted.current = true;
      return undefined;
    }

    return show ? enter : exit;
  }, [enter, exit, show, skipOnMount]);

  const onAnimationEnd = useCallback(() => {
    if (animationClass === exit) {
      setShouldRender(false);
      setDelayedThings(thingsToWait);
    }
  }, [animationClass, exit, thingsToWait]);

  useEffect(() => {
    if (animationClass === enter) {
      setDelayedThings(thingsToWait);
      setShouldRender(true);
    }

    if (isInfinite) {
      onAnimationEnd();
    }
  }, [animationClass, enter, isInfinite, onAnimationEnd, thingsToWait]);

  return {
    animationClass,
    delayedThings,
    onAnimationEnd,
    shouldRender,
  };
};

