export const createSyntheticEvent = (el: HTMLInputElement, type: string) => {
  if (el) {
    // Workaround for react-hook-form getValues and watch
    const nativeEvent = new InputEvent(type === 'change' ? 'input' : type, {
      bubbles: true,
      cancelable: false,
      composed: true,
    });

    el.dispatchEvent(nativeEvent);

    /**
     * This defaultPrevented code is directly copied from reactDOM sourcecode:
     * The using of returnValue (deprecated) is thinked to handle old browsers
     */
    const defaultPrevented =
      nativeEvent.defaultPrevented != null
        ? nativeEvent.defaultPrevented
        : nativeEvent.returnValue === false;

    return {
      ...nativeEvent,
      currentTarget: nativeEvent.target as EventTarget & HTMLInputElement,
      isDefaultPrevented: defaultPrevented ? () => true : () => false,
      isPropagationStopped: () => false,
      nativeEvent: {
        ...nativeEvent,
        relatedTarget: null,
      },
      persist: () => {},
      relatedTarget: null,
      target: nativeEvent.target as EventTarget & HTMLInputElement,
      type,
      _reactName: `on${type.slice(0, 1).toUpperCase()}${type.slice(1)}`,
      _targetInst: null,
    };
  }
  return null;
};

