import { forwardRef } from 'react';
import * as I from '../../../../inputs.style';
import { ErrorMessage } from '../../../ErrorMessage';
import { SideIcon } from '../../../SideIcon';
import { useInputs } from '../../../../inputs.hook';
import { IInputAndButton } from './inputAndButton.models';
import * as S from './inputAndButton.style';

export const InputAndButton = forwardRef<HTMLInputElement, IInputAndButton>(
  (
    {
      'aria-controls': ariaControls,
      'aria-expanded': ariaExpanded,
      'aria-label': ariaLabel,
      bg = '#fff',
      children,
      disabled: _disabled,
      error: _error,
      label,
      loading,
      notDisableIfLoading,
      onClick,
      onSvgAnimationEnd,
      placeholder,
      svg,
      svgClassName,
      style,
      styled,
      variant = 'animated',
      ...rest
    },
    ref
  ) => {
    const { disabled, id, error } = useInputs({
      _disabled,
      _error,
      loading,
      notDisableIfLoading,
    });

    return (
      <I.Container style={style} styled={styled} variant={variant}>
        <I.Label>
          <input
            {...rest}
            aria-errormessage={id}
            aria-invalid={!!error}
            aria-label={ariaLabel}
            disabled={disabled}
            hidden
            placeholder={placeholder || label}
            tabIndex={-1}
            type="text"
            ref={ref}
          />
          <S.Button
            aria-controls={ariaControls}
            aria-errormessage={id}
            aria-expanded={ariaExpanded}
            aria-invalid={!!error}
            aria-label={ariaLabel}
            bg={bg}
            className={`${children ? 'valued' : ''}`}
            disabled={disabled}
            error={error}
            onClick={onClick}
            type="button"
            variant={variant}
          >
            {children ||
              (placeholder && variant?.includes('static') ? (
                <S.Placeholder>{placeholder}</S.Placeholder>
              ) : null)}
          </S.Button>
          <I.LabelPlaceholder bg={bg} disabled={disabled} variant={variant}>
            {label}
          </I.LabelPlaceholder>
          <SideIcon
            error={error}
            disabled={disabled}
            loading={loading}
            onSvgAnimationEnd={onSvgAnimationEnd}
            svg={svg}
            svgClassName={svgClassName}
            variant={variant}
          />
        </I.Label>
        <ErrorMessage error={error} id={id} variant={variant} />
      </I.Container>
    );
  }
);

